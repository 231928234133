import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";

const AspectRatio = ({ innerSx, children, ratio, sx, ...rest }) => {
  const setRatio = (r) => `calc((${r[1]} / ${r[0]}) * 100%)`;
  const ratios =
    ratio && ratio.length && Array.isArray(ratio[0])
      ? ratio.map((r) => r && setRatio(r))
      : setRatio(ratio);
  const shapeStyled = {
    "&:before": {
      content: '""',
      display: "block",
      pointerEvents: "none",
      pt: ratios,
      width: "100%",
    },
    "> *": {
      alignItems: "center",
      bottom: " 0",
      display: "flex",
      justifyContent: "center",
      left: " 0",
      position: " absolute",
      right: " 0",
      top: " 0",
      ...innerSx,
    },
    display: "inline-block",
    "img, svg": {
      // flex: 'auto',
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      width: "-webkit-min-content",
    },
    maxWidth: "100%",
    position: "relative",
    verticalAlign: "top",
    width: "100%",
    ...sx,
  };
  return (
    <Box sx={shapeStyled} {...rest}>
      {children}
    </Box>
  );
};
AspectRatio.propTypes = {
  children: PropTypes.node,
  innerSx: PropTypes.shape({}),
  ratio: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number])
  ),
  sx: PropTypes.shape({}),
};

AspectRatio.defaultProps = {
  children: null,
  innerSx: {},
  ratio: [1, 1],
  sx: {},
};

export default AspectRatio;

import React from "react";
import * as PropTypes from "prop-types";
import Box from "../Box";
import AspectRatio from "../AspectRatio";

const Hexagon = ({ children, sx, wrapperSx, horizontal, ...rest }) => {
  return (
    <AspectRatio sx={wrapperSx} {...rest}>
      <Box
        sx={{
          bg: "white",
          clipPath: `polygon(${
            horizontal
              ? "25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%"
              : "50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%"
          })`,
          height: horizontal ? "calc(100% - 13.25%)" : "100%",
          m: "auto",
          width: !horizontal ? "calc(100% - 13.25%)" : "100%",
          ...sx,
        }}
      />
      {children}
    </AspectRatio>
  );
};

Hexagon.propTypes = {
  children: PropTypes.node,
  horizontal: PropTypes.bool,
  sx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

Hexagon.defaultProps = {
  children: null,
  horizontal: false,
  sx: {},
  wrapperSx: {},
};

export default Hexagon;

import PropTypes from "prop-types";
import React, { useContext } from "react";
import ReactModal from "react-modal";
import { createGlobalStyle, ThemeContext } from "styled-components";
import Box from "../Box";
import Button from "../Button";
import Heading from "../Heading";
import Icon from "../Icon";

const StyledGlobal = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: ${(p) => p.theme.zIndices[5]};
  }
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  .ReactModal__Content {
    transition: transform 200ms ease-in-out;
    transform: translate(-50%, -52%);
  }
  .ReactModal__Content--after-open{
    transform: translate(-50%, -50%);
  }
  .ReactModal__Content--before-close{
    transform: translate(-50%, -52%);
  }
`;

const Modal = ({
  applyForm,
  bodySx,
  cancelText,
  children,
  closeText,
  footer,
  footerContent,
  onClose,
  onSubmit,
  style,
  submitText,
  submitting,
  title,
  ...rest
}) => {
  const theme = useContext(ThemeContext);

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={200}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={{
        content: {
          border: "none",
          bottom: "auto",
          boxShadow: theme.shadows[3],
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${theme.space[5]} * 2`,
          left: "50%",
          maxWidth: theme.sizes.maxWidths.lg,
          overflow: "visible",
          padding: 0,
          right: "auto",
          top: "50%",
          width: `calc(100% - ${theme.space[5]} * 2`,
          ...style.content,
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          ...style.overlay,
        },
      }}
      {...rest}
    >
      <StyledGlobal />
      <Button
        disabled={submitting}
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: theme.zIndices[1],
        }}
        variant="buttons.white.icon"
      >
        {closeText || (
          <Icon svg="close" sx={{ color: "primary", width: "1.35rem" }} />
        )}
      </Button>
      {title && (
        <Box
          as="header"
          sx={{
            borderBottom: "1px solid #F3F6F9",
            borderTopLeftRadius: theme.radii[2],
            borderTopRightRadius: theme.radii[2],
            p: 6,
            position: "relative",
            textTransform: "none",
          }}
          variant="sections.white"
        >
          <Heading as="h4" sx={{ color: "primary", fontSize: 2 }}>
            {title}
          </Heading>
        </Box>
      )}
      <Box
        sx={{
          flex: "auto",
          overflow: "auto",
          p: 6,
          ...bodySx,
        }}
      >
        {children}
      </Box>
    </ReactModal>
  );
};

Modal.propTypes = {
  applyForm: PropTypes.bool,
  bodySx: PropTypes.shape({}),
  cancelText: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeText: PropTypes.string || PropTypes.node,
  footer: PropTypes.bool,
  footerContent: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  style: PropTypes.shape({
    content: PropTypes.shape({}),
    overlay: PropTypes.shape({}),
  }),
  submitText: PropTypes.string,
  submitting: PropTypes.bool,
  title: PropTypes.string,
};

Modal.defaultProps = {
  applyForm: null,
  bodySx: {},
  cancelText: "Cancel",
  closeText: null,
  footer: null,
  footerContent: null,
  onSubmit: null,
  style: {},
  submitText: "Submit",
  submitting: false,
  title: null,
};

export default Modal;

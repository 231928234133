import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const ServicesContainer = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allServicesPageJson {
            edges {
              node {
                bannerHeading
                bannerHeadingContent
                id
                seo {
                  description
                  keywords
                  title
                }
                servicesDetailsSection {
                  servicesDetails
                  servicesHeading
                  servicesIcon
                  servicesPriority
                  servicesFlag
                }
              }
            }
          }
        }
      `}
      render={({ allServicesPageJson: { edges: servicesPageData } }) =>
        children(
          servicesPageData.map((e) => ({
            ...e.node,
          }))
        )
      }
    />
  );
};

ServicesContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ServicesContainer;

import React, { useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
// import get from "lodash/get";
import PropTypes from "prop-types";
import { composeValidations, email, required } from "../../validations";
import Box from "../Box";
import Textarea from "../TextArea";
import Button from "../Button";
import Input from "../Input";
import FileUpload from "../FileUpload";
import Dropdown from "../Dropdown";
import {
  CONTACT_EMAIL_DEFAULT_VALUES,
  SUBJECTS,
} from "../../utilities/constants";
import config from "../../config.json";
import GoogleCaptcha from "../GoogleCaptcha";

const ContactForm = ({ opening }) => {
  const gap = 5;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    mb: gap,
    minWidth: ["100%", null, null, "50%"],
    pr: gap,
  };
  const [fileAttachment, setFileAttachment] = useState({});
  const [message, setMessage] = useState(null);
  const [captchaFlag, setCaptchaFlag] = useState(true);
  return (
    <FinalForm
      onSubmit={async (values, form) => {
        const mailOptions = {
          emailMessage: values.emailMessage
            ? values.emailMessage.replace(/\n/g, "<br/>")
            : " ",
          emailSubject: values.emailSubject
            ? values.emailSubject
            : CONTACT_EMAIL_DEFAULT_VALUES.EMAIL_SUBJECT,
          name: values.name,
          phoneNumber: values.mobile,
          recaptcha: values.recaptcha,
          toEmailAddress: values.email,
        };
        const updatedMailOptions =
          Object.keys(fileAttachment).length > 0
            ? {
                ...mailOptions,
                fileAttachment,
              }
            : mailOptions;
        await fetch(config.emailApi, {
          body: JSON.stringify(updatedMailOptions),
          headers: { "Content-Type": "application/json" },
          method: "POST",
        })
          .then((res) => {
            setCaptchaFlag(true);
            Object.keys(values).forEach((key) => {
              if (key !== "recaptcha") {
                form.change(key, undefined);
                form.resetFieldState(key);
              }
            });
            if (res.status === 200 || res.status === 201) {
              setMessage(
                "Thank you for contacting us. we will be in touch with you very soon."
              );
            } else {
              setMessage(
                "There is some technical problem, try after some time."
              );
            }
          })
          .catch((error) => {
            if (error) {
              setMessage(
                "There is some technical problem, try after some time."
              );
            }
          });
      }}
      render={(formContext) => (
        <form onSubmit={formContext.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              mb: -gap,
              mr: -gap,
            }}
          >
            <Box
              as={Field}
              component={Input}
              label="Name"
              name="name"
              validate={required}
              variant="inputs.primary"
              wrapperSx={{ ...colStyled, minWidth: "100%" }}
            />
            <Box
              as={Field}
              component={Input}
              label="Email"
              name="email"
              validate={composeValidations(required, email)}
              variant="inputs.primary"
              wrapperSx={colStyled}
            />
            <Box
              as={Field}
              component={Input}
              label="Mobile"
              name="mobile"
              variant="inputs.primary"
              wrapperSx={colStyled}
            />
            {!opening && (
              <Box
                as={Field}
                component={Dropdown}
                disabled={opening}
                label="Subject"
                name="emailSubject"
                options={SUBJECTS}
                validate={required}
                // placeholder={opening ? "Applied for a Job" : " "}
                wrapperSx={{ ...colStyled, minWidth: "100%" }}
              />
            )}
            {opening && (
              <Box
                as={Field}
                component={FileUpload}
                name="attachment"
                setFileAttachment={setFileAttachment}
                wrapperSx={{ ...colStyled, minWidth: "100%", mt: 2 }}
              />
            )}
            <GoogleCaptcha
              captchaFlag={captchaFlag}
              formContext={formContext}
              setCaptchaFlag={setCaptchaFlag}
            />
            <Box
              as={Field}
              component={Textarea}
              label="Message"
              maxLength="200"
              name="emailMessage"
              rows={5}
              validate={required}
              variant="inputs.primary"
              wrapperSx={{ ...colStyled, minWidth: "100%" }}
            />
            <Box
              sx={{
                ...colStyled,
                display: "flex",
                justifyContent: "flex-end",
                minWidth: "100%",
              }}
            >
              <Button
                disabled={formContext.pristine || formContext.submitting}
                submitting={formContext.submitting}
                type="submit"
                variant="buttons.primary"
              >
                {!opening ? "Send Message" : "Apply"}
              </Button>
            </Box>
            {formContext.submitSucceeded && (
              <Box
                sx={{
                  ...colStyled,
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "100%",
                }}
              >
                <Box
                  as="span"
                  sx={{
                    color: "secondary",
                    fontSize: 1,
                    fontWeight: "semibold",
                  }}
                >
                  {message}
                </Box>
              </Box>
            )}
          </Box>
        </form>
      )}
    />
  );
};

ContactForm.propTypes = {
  opening: PropTypes.bool,
};

ContactForm.defaultProps = {
  opening: false,
};
export default ContactForm;

export const footerLinks = [
  { label: "Services", path: "/services", submenu: null },
  { label: "About", path: "/about", submenu: null },
  { label: "Career", path: "/career", submenu: null },
  { label: "Blogs", path: "/blogs", submenu: null },
  {
    label: "Contact",
    path: "/contact",
    submenu: null,
  },
];

export const socialMedia = [
  {
    icon: "twitter",
    link: "https://twitter.com/briskteq",
  },
  {
    icon: "facebook",
    link: "https://www.facebook.com/Briskteq",
  },
  {
    icon: "linkedin",
    link: "http://linkedin.com/company/briskteq",
  },
  {
    icon: "instagram",
    link: "https://www.instagram.com/briskteq/",
  },
];

export const socialMediaBlogs = [
  {
    icon: "twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "linkedin",
    link: "https://www.linkedin.com/",
  },
  {
    icon: "mail",
    link: "https://www.linkedin.com/",
  },
];

export const pages = [
  { label: "Services", path: "/services", submenu: null },
  { label: "About", path: "/about", submenu: null },
  { label: "Career", path: "/career", submenu: null },
  { label: "Blogs", path: "/blogs", submenu: null },
  { label: "Contact", path: "/contact", submenu: null },
];

import React from "react";
import { footerLinks, socialMedia } from "../../data/pages";
import Box from "../Box";
import LinkTo from "../LinkTo";
import Copyright from "../Copyright";
import Icon from "../Icon";
import Divider from "../Divider";
import Image from "../Image";
import {
  COMPANY_EMAIL,
  COMPANY_MOBILE_NUMBER,
} from "../../utilities/constants";
import Section from "../Section";

const Footer = () => {
  const containerSx = {
    display: "flex",
    flexDirection: "column",
    fontSize: 0,
    pb: 8,
    pt: [8, 9],
  };
  const linkWrapperStyled = {
    alignItems: "center",
    display: "flex",
    flexDirection: ["column", "row"],
    flexWrap: "wrap",
    justifyContent: [null, "center"],
  };
  const contentSx = {
    color: "white",
    display: "inline-block",
    fontFamily: "secondary",
    fontSize: 1,
    fontWeight: "regular",
    p: 4,
  };
  return (
    <Section
      as="footer"
      containerSx={containerSx}
      sx={{ mb: 0, mt: 0, pb: 0, pt: 0 }}
      variant="sections.dark"
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: ["column", null, null, "row"],
          justifyContent: "space-between",
        }}
      >
        {footerLinks && footerLinks.length > 0 && (
          <Box
            as="ul"
            sx={{
              ...linkWrapperStyled,
              listStyle: "none",
              mx: -4,
              textAlign: ["center", null],
            }}
          >
            {footerLinks.map((item, index) => (
              <Box
                key={`${item.label}_${index}`}
                as="li"
                sx={{ listStyle: "none" }}
              >
                <LinkTo
                  hoverSx={{
                    color: "primary",
                  }}
                  sx={{
                    "&.active": {
                      color: "primary",
                    },
                    ...contentSx,
                  }}
                  to={item.path}
                >
                  {item.label}
                </LinkTo>
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            ...linkWrapperStyled,
            justifyContent: ["center", null],
            mx: -4,
          }}
        >
          <Box
            sx={{
              ...contentSx,
            }}
          >
            {COMPANY_EMAIL.INFO}
          </Box>
          <Box
            sx={{
              ...contentSx,
            }}
          >
            {COMPANY_MOBILE_NUMBER.CONTACT_NUMBER}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: ["column", null, null, "row"],
          justifyContent: "space-between",
          mt: 7,
        }}
      >
        <Box as="a" href="/" sx={{ ...linkWrapperStyled, mx: -4 }}>
          <Icon
            svg="Brisktech-logo-white"
            sx={{ ml: [null, null, null, 15] }}
          />
        </Box>
        <Divider />
        {socialMedia && socialMedia.length > 0 && (
          <Box
            sx={{
              display: "flex",
              mx: -3,
              my: 4,
            }}
          >
            {socialMedia.map((item, index) => (
              <Box
                key={`${item.icon}-${index}`}
                as="a"
                href={item.link}
                rel="noopener norefferer"
                sx={{
                  ":hover": {
                    svg: {
                      fill: "primary",
                    },
                  },
                  color: "primary",
                  cursor: "pointer",
                  mx: 3,
                  width: "1.35rem",
                }}
                target="_blank"
                title={item.icon}
                to={null}
              >
                <Image svgIcon={item.icon} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: ["column", null, null, "row"],
          justifyContent: "center",
          mt: 7,
        }}
      >
        <Copyright
          append="BriskTech. All Rights Reserved."
          sx={{
            ...contentSx,
            p: 0,
            textAlign: "center",
          }}
        />
      </Box>
    </Section>
  );
};

export default Footer;

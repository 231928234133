import React from "react";
import PropTypes from "prop-types";
import NavBar from "../Navbar";

const Navigation = ({ navLinks, toggled, ...rest }) => {
  const navBarMobileStyled = {
    a: {
      fontSize: [2, null, null, 1],
      fontWeight: "bold",
      justifyContent: "center",
      letterSpacing: 1,
      px: 3,
      py: [2, null, null, 1],
    },
    bg: ["white", null, null, "unset"],
    bottom: ["0", null, null, "unset"],
    boxShadow: [1, null, null, "none"],
    li: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    maxWidth: [`${toggled ? "36rem" : "0rem"}`, null, null, "100%"],
    overflow: ["hidden", null, null, "visible"],
    position: ["fixed", null, null, "static"],
    right: ["0", null, null, "unset"],
    top: ["0", null, null, "unset"],
    transform: [`${toggled ? "" : "translateX(100%)"} `, null, null, "none"],
    // transform: [`${toggled ? "" : "translateX(100%)"} `, null, null, "none"],

    transition: "transform .55s ease-in-out",
    ul: {
      flex: 1,
      flexDirection: ["column", null, null, "unset"],
      p: [4, null, null, 0],
    },
    width: ["calc(100% - 4rem)", null, null, "auto"],
  };
  const navBarStyled = {
    alignItems: "center",
    display: "flex",
    flex: "auto",
    justifyContent: "center",
    ...navBarMobileStyled,
  };
  const menuStyled = {
    a: {
      "&:hover": {
        color: "primary",
      },
    },
    display: "flex",
    justifyContent: "flex-end",
    li: {
      display: "flex",
      flexDirection: "column",
      pb: [7, null, null, 0],
      pl: [0, null, null, 6],
      position: "relative",
    },
  };
  const activeLinkStyled = {
    a: {
      color: "primary",
      position: "relative",
    },
  };
  return (
    <NavBar
      activeLinkSx={activeLinkStyled}
      menuSx={menuStyled}
      navLinks={navLinks}
      sx={navBarStyled}
      {...rest}
    />
  );
};

Navigation.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggled: PropTypes.bool,
};

Navigation.defaultProps = {
  toggled: false,
};

export default Navigation;

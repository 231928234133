import React, { useContext } from "react";
import parse from "html-react-parser";
import { Box } from "reflexbox/src";
import marked from "marked";
import { ThemeContext } from "styled-components";

const mdToHtml = (mdText, sx) => {
  const theme = useContext(ThemeContext);
  const noDropcap = {
    color: "inherit",
    float: "none",
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    mr: "0",
    mt: "0",
  };

  return (
    <Box
      sx={{
        "*": { fontSize: "inherit", lineHeight: "inherit" },
        a: {
          "&:hover": { textDecoration: "underline" },
          color: theme.colors.primary,
        },
        blockquote: {
          "&>p": { fontSize: 3, fontWeight: theme.fontWeights.regular, mb: 0 },
          bg: "white",
          borderLeftColor: "text.subtle",
          borderLeftStyle: "solid",
          borderLeftWidth: "0.1rem",
          color: theme.colors.borderGray,
          m: 0,
          mb: 4,
          mt: 4,
          p: 2,
          "p:first-of-type .dropcap": noDropcap,
        },
        h2: { lineHeight: 0, mb: 4, mt: 4 },
        h3: { lineHeight: 1, mb: 4, mt: 4 },
        hr: {
          borderStyle: "none",
          borderTopColor: "border",
          borderTopStyle: "solid",
          borderTopWidth: "1px",
          height: "1px",
          m: 0,
          maxWidth: "maxWidths.paragraph",
          p: 0,
        },
        // lineHeight: theme.lineHeights[4],
        p: {
          "& > img": {
            height: "100%",
            width: "100%",
          },
          mb: 4,
        },
        pre: {
          "&>p": { mb: 0 },
          bg: "white",
          borderLeftColor: "text.subtle",
          borderLeftStyle: "solid",
          borderLeftWidth: "0.9rem",
          color: theme.colors.borderGray,
          fontStyle: "italic",
          fontWeight: "semibold",
          m: 0,
          mb: 4,
          mt: 4,
          p: 4,
          "p:first-of-type .dropcap": noDropcap,
          whiteSpace: "break-spaces",
        },
        "ul,ol": { fontSize: theme.fontSizes[2], pl: 4 },
        ...sx,
      }}
    >
      {parse(marked(mdText))}
    </Box>
  );
};

export default mdToHtml;

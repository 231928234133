import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Box from "../Box";

const BlogList = ({ sx, imageSx, data: blogsData }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        ...sx,
      }}
    >
      {blogsData.length > 0 &&
        blogsData.map((blog, i) => (
          <Box
            key={`blog${i}`}
            sx={{
              ":hover": {
                boxShadow: 3,
              },
              ":hover > div > .blogTitle ": {
                color: "primaryDark",
              },
              bg: "white",
              borderRadius: 2,
              boxShadow: 2,
              display: "flex",
              flexDirection: "column",
              height: "332px",
              m: 6,
              width: "255px",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url('${blog.blogImage}')`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
                display: "flex",
                justifyContent: "center",
                minHeight: "50%",
                ...imageSx,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "50%",
                justifyContent: "space-between",
                mx: 6,
                my: 6,
              }}
            >
              <Box
                as="a"
                className="blogTitle"
                href={`/blog/${get(blog, "blogId", "")}`}
                sx={{
                  color: "primary",
                  fontFamily: "secondary",
                  fontSize: 1,
                }}
              >
                {blog.title}
              </Box>
              <Box
                sx={{
                  color: "text.secondary",
                  display: "flex",
                  fontFamily: "secondary",
                  fontSize: 0,
                  justifyContent: "space-between",
                }}
              >
                <Box>{blog.author}</Box>
                <Box>{blog.publishDate}</Box>
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

BlogList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  imageSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

BlogList.defaultProps = {
  data: {},
  imageSx: {},
  sx: {},
};

export default BlogList;

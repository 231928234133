import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const AboutContainer = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allAboutPageJson {
            edges {
              node {
                bannerHeading
                bannerHeadingContent
                bannerImage
                id
                seo {
                  description
                  keywords
                  title
                }
                steps {
                  stepDescription
                  stepTitle
                }
              }
            }
          }
        }
      `}
      render={({ allAboutPageJson: { edges: aboutPageData } }) =>
        children(
          aboutPageData.map((e) => ({
            ...e.node,
          }))
        )
      }
    />
  );
};

AboutContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default AboutContainer;

import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const BlogsContainer = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allBlogsPageJson {
            edges {
              node {
                id
                heading
                highlights
                blogs {
                  author
                  blogDescription
                  blogId
                  blogImage
                  publishDate
                  title
                  tags {
                    tagLink
                    tagTitle
                  }
                }
                seo {
                  description
                  keywords
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allBlogsPageJson: { edges: blogsPageData } }) =>
        children(
          blogsPageData.map((e) => ({
            ...e.node,
          }))
        )
      }
    />
  );
};

BlogsContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default BlogsContainer;

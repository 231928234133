import React from "react";
import get from "lodash/get";
import Box from "../../components/Box";
import Section from "../../components/Section";
import Heading from "../../components/Heading";
import Image from "../../components/Image";
import {
  COMPANY_EMAIL,
  COMPANY_LOCATION,
  COMPANY_MOBILE_NUMBER,
  PAGE_ID,
} from "../../utilities/constants";
import mdToHtml from "../../utilities/mdToHtml";
import svgNameFormat from "../../utilities/svgNameFormat";
import ContactContainer from "../../containers/ContactContainer";
import ContactForm from "../../components/ContactForm/ContactForm";
import SEO from "../../components/SEO";

const Contact = () => {
  return (
    <ContactContainer>
      {(contactPageData) => {
        const contactData = contactPageData
          .map((ele) => (ele.id === PAGE_ID.CONTACT_PAGE ? ele : null))
          .find((el) => el);
        const seo = get(contactData, "seo", {});
        return (
          <>
            <SEO
              description={get(seo, "description", "")}
              keywords={get(seo, "keywords", "")}
              title={get(seo, "title", "")}
            />
            <Section
              containerSx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              sx={{ mb: 0, mt: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: ["100%", null, "50%"],
                }}
              >
                <Image
                  svgIcon={svgNameFormat(contactData.bannerImage)}
                  sx={{
                    textAlign: ["center", null, "left"],
                    width: ["100%", null, "50%"],
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: ["center", null, "left"],
                    // pr: [0, null, 8],
                    width: ["100%", null, "80%"],
                  }}
                >
                  <Heading as="h1" sx={{ pb: 5 }}>
                    {get(contactData, "bannerHeading", "")}
                  </Heading>
                  <p>{mdToHtml(contactData.bannerHeadingContent)}</p>
                  <Box
                    sx={{
                      color: "primary",
                      display: "flex",
                      flexDirection: "column",
                      mt: 6,
                    }}
                  >
                    <p>{COMPANY_LOCATION.SURAT}</p>
                    <p>{COMPANY_EMAIL.INFO}</p>
                    <p>{COMPANY_MOBILE_NUMBER.CONTACT_NUMBER}</p>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: ["100%", null, "50%"] }}>
                <ContactForm />
              </Box>
            </Section>
          </>
        );
      }}
    </ContactContainer>
  );
};

export default Contact;

import React from "react";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import Box from "../Box";
import LinkTo from "../LinkTo";
import Icon from "../Icon";

const NavBar = ({
  activeLinkSx,
  append,
  ignore,
  linkSx,
  menuSx,
  navLinks,
  onClick,
  prepend,
  sx,
}) => {
  const hasLocation = (location, path) => {
    const { pathname } = location;
    return pathname.includes(path);
  };
  const linkStyled = {
    alignItems: "center",
    cursor: "pointer",
    display: "inline-flex",
  };
  const setIconAndLabel = (icon, label) => (
    <>
      {icon && <Icon svg={icon} sx={{ width: "1.35rem" }} />}
      <Box as="span">{label}</Box>
    </>
  );
  const setNevGroup = (linkList) => (
    <Location>
      {({ location }) => (
        <Box as="ul" sx={{ ...menuSx, listStyle: "none" }}>
          {linkList &&
            linkList.length > 0 &&
            linkList.map((page) => {
              return !ignore.includes(page.label) ? (
                <Box
                  key={`nav-page-${page.label}`}
                  as="li"
                  sx={
                    hasLocation(location, page.path)
                      ? {
                          ...linkSx,
                          ...activeLinkSx,
                          listStyle: "none",
                        }
                      : { ...linkSx, listStyle: "none" }
                  }
                >
                  {page.action ? (
                    <Box as="a" onClick={() => page.action()} sx={linkStyled}>
                      {setIconAndLabel(page.icon, page.label)}
                    </Box>
                  ) : (
                    <LinkTo onClick={onClick} sx={linkStyled} to={page.path}>
                      {setIconAndLabel(page.icon, page.label)}
                    </LinkTo>
                  )}

                  {page.submenu &&
                    page.submenu.length > 0 &&
                    setNevGroup(page.submenu)}
                </Box>
              ) : null;
            })}
        </Box>
      )}
    </Location>
  );
  return (
    <Box
      as="nav"
      sx={{
        ...sx,
      }}
    >
      {prepend}
      {setNevGroup(navLinks)}
      {append}
    </Box>
  );
};

NavBar.propTypes = {
  activeLinkSx: PropTypes.shape({}),
  append: PropTypes.node,
  ignore: PropTypes.arrayOf(PropTypes.string),
  linkSx: PropTypes.shape({}),
  menuSx: PropTypes.shape({}),
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func,
  prepend: PropTypes.node,
  sx: PropTypes.shape({}),
};

NavBar.defaultProps = {
  activeLinkSx: {},
  append: null,
  ignore: [],
  linkSx: {},
  menuSx: {},
  onClick: null,
  prepend: null,
  sx: {},
};

export default NavBar;

import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const IndexContainer = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allIndexPageJson {
            edges {
              node {
                about {
                  aboutContent
                  aboutHeading
                  servicesHexagon {
                    serviceImage
                    serviceTitle
                  }
                }
                clients {
                  clientDescription
                  clientLogoImage
                  clientName
                  clientProfileImage
                }
                heading {
                  buttonContent
                  heading
                  headingContent
                  headingBanner {
                    hexagonImage
                    hexagonTitle
                  }
                }
                id
                keyCompetencies {
                  keyCompetencyHeading
                  keyCompetencyList {
                    keyCompetencyDescription
                    keyCompetencyImage
                    keyCompetencyTitle
                  }
                }
                seo {
                  description
                  keywords
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allIndexPageJson: { edges: indexPageData } }) =>
        children(
          indexPageData.map((e) => ({
            ...e.node,
          }))
        )
      }
    />
  );
};

IndexContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default IndexContainer;

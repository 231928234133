import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";

const Divider = ({ label, labelSx, sx, ...rest }) => {
  const labelStyled = {
    bg: "text.subtle",
    border: "1px solid",
    borderColor: "text.subtle",
    borderRadius: 5,
    color: "text.subtle",
    fontSize: 0,
    fontWeight: "semiBold",
    left: "50%",
    position: "absolute",
    px: 2,
    py: 1,
    top: "50%",
    transform: "translate(-50%, -50%)",
    ...labelSx,
  };
  return (
    <Box
      sx={{
        borderColor: "success",
        borderStyle: "solid",
        borderWidth: "1px 0 0 0",
        justifyContent: "center",
        my: 6,
        position: "relative",
        width: "60%",
        ...sx,
      }}
    >
      {label && (
        <Box sx={labelStyled} {...rest}>
          {label}
        </Box>
      )}
    </Box>
  );
};

Divider.propTypes = {
  label: PropTypes.node || PropTypes.string,
  labelSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Divider.defaultProps = {
  label: null,
  labelSx: {},
  sx: {},
};

export default Divider;

/* eslint-disable prefer-destructuring */

import { rgba } from "polished";

const briskBlue = "#4CB0DE";
const briskGreen = "#84C341";
const deepBlue = "#0A2239";
const lightGray = "#92989C";
const darkBlue = "#389ECD";
const whiteBlue = "#CFE8FF";
const grayBlue = "rgba(76, 176, 222, 0.4)";
const red = "#DC3545";
const yellow = "#FFC107";
const black = "#222222";
const white = "#FFFFFF";
const gray = "#F3F6F9";
const borderGray = "#92989C";

const breakpoints = ["544px", "710px", "992px", "1200px", "1400px"];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const deepBlueTransparent01 = rgba(`${deepBlue}`, 0.1);
const deepBlueTransparent06 = rgba(`${deepBlue}`, 0.06);
const deepBlueTransparent04 = rgba(`${deepBlue}`, 0.04);
const deepBlueTransparent25 = rgba(`${deepBlue}`, 0.25);
const transitionAll = "all 0.35s ease-in-out";
const buttonStyled = {
  common: {
    "&:hover": {
      bg: "primaryMedium",
      borderColor: "primaryMedium",
      boxShadow: 2,
    },
    alignItems: "center",
    borderRadius: 1,
    borderStyle: "solid",
    borderWidth: "2px",
    boxShadow: 1,
    color: "white",
    display: "inline-flex",
    fontSize: 0,
    fontWeight: "semiBold",
    height: "48px",
    justifyContent: "center",
    letterSpacing: 0,
    lineHeight: 2,
    minHeight: "calc(0.5rem * 2 + 1.6em)",
    minWidth: "180px",
    px: 2,
    py: 1,
    textTransform: "uppercase",
    transition: transitionAll,
  },
  disabled: {
    bg: "primaryTransparent",
    border: "none",
    boxShadow: 0,
    pointerEvents: "none",
  },
  icon: {
    minWidth: "48px",
    pb: 2,
    pl: 2,
    pr: 2,
    pt: 2,
  },
  outline: {
    borderColor: "primary",
    color: "primary",
  },
  secondary: {
    common: {
      "&:hover": {
        borderColor: "white",
        boxShadow: 2,
        color: "primaryMedium",
      },
      bg: "white",
      borderColor: "white",
      borderWidth: "1px",
      boxShadow: 1,
      color: "primary",
    },
    disabled: {
      bg: "white",
      boxShadow: 0,
      color: "primaryTransparent",
    },
    solid: {
      bg: "primary",
    },
  },
  solid: {
    bg: "primary",
    color: "white",
  },
  white: {
    bg: "white",
    color: "primary",
  },
};

export default {
  baseFontSizesForBreakpoint: ["5vw", "4vw", "2.5vw", "1.5vw", "18px"],
  breakpoints,
  colors: {
    black,
    body: gray,
    borderGray,
    error: red,
    primary: briskBlue,
    primaryDark: deepBlue,
    primaryLight: whiteBlue,
    primaryMedium: darkBlue,
    primaryTransparent: grayBlue,
    secondary: briskGreen,
    success: briskGreen,
    text: {
      link: deepBlue,
      primary: deepBlue,
      secondary: lightGray,
    },
    warning: yellow,
    white,
  },
  filter: [
    `drop-shadow(0px 0px 0px transparent)`,
    `drop-shadow(0px 1px 3px ${deepBlueTransparent01}) drop-shadow(0px 1px 2px ${deepBlueTransparent06})`,
    `drop-shadow(0px 10px 15px ${deepBlueTransparent01}) drop-shadow(0px 4px 6px ${deepBlueTransparent04})`,
    `drop-shadow(0px 25px 50px ${deepBlueTransparent25})`,
  ],
  fonts: {
    primary: '"Roboto Condensed", sans-serif',
    secondary: '"Cabin", sans-serif',
  },
  fontSizes: ["14px", "18px", "24px", "28px", "36px", "45px", "48px"],
  fontWeights: {
    black: 800,
    bold: 700,
    heavy: 900,
    light: 300,
    medium: 500,
    regular: 400,
    semiBold: 600,
  },
  letterSpacings: ["0.05em", "0.1em", "0.125em"],
  lineHeights: ["56px", "55px", "42px", "44px", "38px", "33px", "22px", "17px"],
  radii: [0, "6px", "8px", "9999px"],
  shadows: [
    "none",
    `0px 1px 3px ${deepBlueTransparent01}, 0px 1px 2px ${deepBlueTransparent06}`,
    `0px 10px 15px -3px ${deepBlueTransparent01}, 0px 4px 6px -2px ${deepBlueTransparent04}`,
    `0px 25px 50px -14px ${deepBlueTransparent25}`,
  ],
  sizes: {
    iconWidth: {
      lg: "84px",
      md: "64px",
      sm: "24px",
      xl: "120px",
    },
    maxWidths: {
      button: "14rem",
      lg: "960px",
      md: "710px",
      sm: "544px",
      xl: "1200px",
    },
  },
  space: [
    "0", // 0 0px
    "0.25rem", // 1  4px
    "0.375rem", // 2 6px
    "0.5rem", // 3  8px
    "0.75rem", // 4  12px
    "1rem", // 5  16px
    "1.25rem", // 6  20px
    "2rem", // 7  32px
    "3.40rem", // 8  54.4px
    "5.25rem", // 9  84px
    "8.5rem", // 10  136px
    "5.05555rem", // 11  80.88px
    "7.05555rem", // 12  112.889px
    "2.75rem", // 13    44px
    "14px",
  ],
  variants: {
    buttons: {
      outline: {
        ...buttonStyled.common,
        "&:hover": {
          ...buttonStyled.common["&:hover"],
          bg: "white",
          borderColor: "primary",
        },
        ...buttonStyled.outline,
        disabled: {
          ...buttonStyled.common,
          ...buttonStyled.disabled,
          ...buttonStyled.outline,
        },
        icon: {
          ...buttonStyled.common,
          "&:hover": {
            ...buttonStyled.common["&:hover"],
            bg: "white",
            borderColor: "primaryMedium",
          },
          ...buttonStyled.outline,
          ...buttonStyled.icon,
        },
      },
      primary: {
        ...buttonStyled.common,
        "&:hover": {
          ...buttonStyled.common["&:hover"],
        },
        ...buttonStyled.outline,
        bg: "primary",
        borderColor: "primary",
        color: "white",
        disabled: {
          ...buttonStyled.common,
          ...buttonStyled.disabled,
        },
        icon: {
          ...buttonStyled.common,
          "&:hover": {
            ...buttonStyled.common["&:hover"],
          },
          ...buttonStyled.outline,
          bg: "primary",
          borderColor: "primary",
          color: "white",
          ...buttonStyled.icon,
        },
      },
      secondary: {
        ...buttonStyled.common,
        ...buttonStyled.secondary.common,
        disabled: {
          ...buttonStyled.common,
          ...buttonStyled.secondary.common,
          ...buttonStyled.secondary.disabled,
        },
        icon: {
          ...buttonStyled.common,
          ...buttonStyled.secondary.common,
          ...buttonStyled.icon,
        },
        outline: {
          ...buttonStyled.common,
          ...buttonStyled.secondary.common,
          disabled: {
            ...buttonStyled.common,
            ...buttonStyled.disabled,
            ...buttonStyled.secondary.common,
          },
          icon: {
            ...buttonStyled.common,
            ...buttonStyled.secondary.common,
            ...buttonStyled.icon,
          },
        },
      },
    },
    // cards: {
    //   bg: 'white',
    //   borderRadius: 3,
    //   boxShadow: 1,
    //   display: 'inline-flex',
    //   flex: 1,
    //   flexDirection: 'column',
    //   overflow: 'hidden',
    //   p: 7,
    //   position: 'relative',
    //   transition: 'all .12s ease-in-out',
    // },
    error: {
      boxShadow: 0,
      fontSize: "14px",
      fontWeight: "regular",
      left: 0,
      ml: 1,
      mt: 1,
      position: "absolute",
      top: "100%",
    },
    headings: {
      primary: {
        color: "primary",
      },
    },
    inputs: {
      error: {
        "&:focus": { borderColor: "borderGray", boxShadow: 2 },
        bg: "white",
        borderColor: "error",
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: "1px",
        color: "text.primary",
        minHeight: `calc(0.5rem * 2 + 1.6em)`,
        px: 5,
        py: 1,
      },
      primary: {
        "&:focus": { borderColor: "borderGray", boxShadow: 2 },
        bg: "white",
        borderColor: "borderGray",
        borderRadius: 1,
        borderStyle: "solid",
        borderWidth: "1px",
        color: "text.primary",
        fontSize: 1,
        minHeight: "calc(0.5rem * 2 + 1.6em)",
        px: 5,
        py: 1,
      },
    },
    label: {
      color: "text.secondary",
      fontSize: 1,
      fontWeight: "regular",
      mb: 2,
      ml: 1,
      textTransform: "capitalize",
    },
    sections: {
      dark: {
        bg: "primaryDark",
        color: "white",
      },
      light: {
        bg: "primaryLight",
      },
      primary: {
        bg: "primary",
        color: "white",
      },
      primaryMedium: {
        bg: "primaryMedium",
        color: "white",
      },
      white: {
        bg: "white",
        color: "primaryDark",
      },
    },
  },
  zIndices: [0, 1, 10, 20, 50, 100, 9999],
};

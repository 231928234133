import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Field } from "react-final-form";
import Box from "../Box";
import { required } from "../../validations";
import Input from "../Input";

const GoogleCaptcha = ({ formContext, captchaFlag, setCaptchaFlag }) => {
  const [token, setToken] = useState(null);
  const [flag, setFlag] = useState(captchaFlag);
  const MINUTE_MS = 60000;
  useEffect(() => {
    if (captchaFlag) {
      setFlag(true);
    }
  }, [captchaFlag]);

  useEffect(() => {
    if (token) {
      formContext.form.change("recaptcha", token);
    }
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFlag(true);
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      <GoogleReCaptchaProvider reCaptchaKey="6Lc5100cAAAAACCmsFuwhfyDbk6h49d0cH84Gm_w">
        {flag && (
          <GoogleReCaptcha
            onVerify={async (token) => {
              if (flag && token) {
                setFlag(false);
                setCaptchaFlag(false);
                setToken(token);
              }
            }}
          />
        )}
      </GoogleReCaptchaProvider>
      <Box
        as={Field}
        component={Input}
        name="recaptcha"
        type="hidden"
        validate={required}
      />
    </Box>
  );
};

GoogleCaptcha.propTypes = {
  captchaFlag: PropTypes.bool,
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
  }).isRequired,
  setCaptchaFlag: PropTypes.func,
};

GoogleCaptcha.defaultProps = {
  captchaFlag: false,
  setCaptchaFlag: () => {},
};

export default GoogleCaptcha;

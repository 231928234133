const svgNameFormat = (image) => {
  if (image.substr(image.lastIndexOf(".") + 1) === "svg") {
    return image
      .substring(image.lastIndexOf("/") + 1)
      .replace(/(\.[^/.]+)+$/, "");
  }
  return image;
};

export default svgNameFormat;

import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";

const Container = ({ children, sx }) => {
  const containerStyled = {
    maxWidth: [
      null,
      "maxWidths.sm",
      "maxWidths.md",
      "maxWidths.lg",
      "maxWidths.xl",
    ],
    mx: "auto",
    position: "relative",
    width: "calc(100% - 1.5rem)",
    ...sx,
  };
  return <Box sx={containerStyled}>{children}</Box>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

Container.defaultProps = {
  sx: {},
};

export default Container;

import React, { useEffect } from "react";
import get from "lodash/get";
import camelCase from "camelcase";
import { Element } from "react-scroll";
import PropTypes from "prop-types";
import { parse } from "query-string";
import Box from "../../components/Box";
import Section from "../../components/Section";
import Heading from "../../components/Heading";
import ConnectBanner from "../../components/ConnectBanner";
import Hexagon from "../../components/Hexagon";
import Image from "../../components/Image";
import { PAGE_ID } from "../../utilities/constants";
import ServicesContainer from "../../containers/ServicesContainer";
import mdToHtml from "../../utilities/mdToHtml";
import svgNameFormat from "../../utilities/svgNameFormat";
import SEO from "../../components/SEO";
import scrollSection from "../../utilities/scrollSection";

const Services = ({ location }) => {
  const { service } = parse(location.search);
  useEffect(() => {
    if (service) {
      scrollSection(service);
    }
  }, []);
  return (
    <ServicesContainer>
      {(servicesPageData) => {
        const servicesData = servicesPageData
          .map((ele) => (ele.id === PAGE_ID.SERVICES_PAGE ? ele : null))
          .find((el) => el);
        const servicesDetailsInfo = get(
          servicesData,
          "servicesDetailsSection",
          []
        );
        const activeServices = servicesDetailsInfo
          .filter((service) => service.servicesFlag === 1)
          .sort((a, b) => {
            return a.servicesPriority - b.servicesPriority;
          });
        const seo = get(servicesData, "seo", {});
        return (
          <>
            <SEO
              description={get(seo, "description", "")}
              keywords={get(seo, "keywords", "")}
              title={get(seo, "title", "")}
            />
            <Section
              containerSx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
              sx={{ mb: 0, mt: 0, pb: 0 }}
            >
              <Heading as="h1" sx={{ mb: 5, textAlign: "center" }}>
                {get(servicesData, "bannerHeading", "")}
              </Heading>
              <Box as="p" sx={{ textAlign: "center", width: ["100%", "60%"] }}>
                {mdToHtml(get(servicesData, "bannerHeadingContent", ""))}
              </Box>
            </Section>
            {activeServices && activeServices.length > 0 && (
              <Section
                containerSx={{
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                sx={{ flexDirection: "row", mb: 0, mt: 0, pb: 0 }}
              >
                {activeServices.map((services, index) => (
                  <Hexagon
                    key={`${services}_${index}`}
                    as="button"
                    onClick={() =>
                      scrollSection(camelCase(services.servicesHeading))
                    }
                    sx={{
                      bg: "primaryLight",
                    }}
                    wrapperSx={{ filter: 2, mb: [3, 3, 0], width: "200px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Image
                        svgIcon={svgNameFormat(services.servicesIcon)}
                        sx={{
                          maxWidth: "iconWidth.md",
                          mb: 5,
                        }}
                      />
                      <Box
                        sx={{
                          color: "primary",
                          fontSize: 0,
                          textTransform: "upperCase",
                          width: "40%",
                        }}
                      >
                        {services.servicesHeading}
                      </Box>
                    </Box>
                  </Hexagon>
                ))}
              </Section>
            )}
            {activeServices && activeServices.length > 0 && (
              <Section sx={{ mt: 0 }}>
                <Box
                  sx={{
                    columnCount: [1, null, 2],
                    columnGap: [0, null, 7],
                  }}
                >
                  {activeServices.map((services, index) => {
                    return (
                      <Element
                        key={`${services}_${index}`}
                        name={camelCase(services.servicesHeading)}
                      >
                        <Box
                          id={camelCase(services.servicesHeading)}
                          sx={{
                            bg: "white",
                            borderRadius: 2,
                            boxShadow: 2,
                            breakInside: "avoid",
                            display: "flex",
                            flexDirection: "column",
                            height: "auto",
                            mb: 7,
                            p: 6,
                            pageBreakInside: "avoid",
                            width: "100%",
                          }}
                        >
                          <Heading
                            as="h4"
                            sx={{
                              color: "primary",
                              fontFamily: "primary",
                              fontWeight: "bold",
                              mb: 6,
                            }}
                          >
                            {services.servicesHeading}
                          </Heading>
                          <Box as="p" sx={{ fontSize: 1, lineHeight: 6 }}>
                            {mdToHtml(services.servicesDetails)}
                          </Box>
                        </Box>
                      </Element>
                    );
                  })}
                </Box>
              </Section>
            )}
            <ConnectBanner />
          </>
        );
      }}
    </ServicesContainer>
  );
};

Services.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Services;

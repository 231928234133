import React from "react";
import { Box } from "reflexbox/src";
import Section from "../Section";
import Button from "../Button";

const ConnectBanner = () => {
  return (
    <Section
      containerSx={{
        alignItems: "center",
        display: "flex",
        flexDirection: ["column", "row"],
        justifyContent: "center",
        textAlign: "center",
      }}
      sx={{
        mb: 0,
        mt: 0,
        width: "100%",
      }}
      variant="sections.white"
    >
      <Box as="p" sx={{ mb: [2, 2, 0], mr: [0, 5] }}>
        Interested in working with us?
      </Box>
      <Button
        link="/contact"
        sx={{
          "&:hover": { bg: "secondary", borderColor: "secondary" },
          bg: "secondary",
          borderColor: "secondary",
        }}
      >
        Let's Connect
      </Button>
    </Section>
  );
};

export default ConnectBanner;

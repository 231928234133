import { scroller } from "react-scroll";

const scrollSection = (sectionName, options) => {
  scroller.scrollTo(sectionName, {
    duration: 550,
    offset: -120,
    smooth: true,
    ...options,
  });
};

export default scrollSection;

import PropTypes from "prop-types";
import React from "react";
import AppBase from "../AppBase";
import Box from "../Box";
import "../../fonts/futura/700.css";
import "../../fonts/raleway/500.css";
import "../../fonts/raleway/600.css";
import config from "../../config.json";
import theme from "../../theme";
import Footer from "../Footer";
import Header from "../Header";

const Layout = ({ children, ...rest }) => {
  return (
    <AppBase config={config} theme={theme}>
      <Box
        sx={{
          left: 0,
          overflowX: "hidden",
          position: "relative",
          top: 0,
          width: "100%",
        }}
      >
        <Header />
        <Box as="main">
          {typeof children === "function" ? children(rest) : children}
        </Box>
        <Footer />
      </Box>
    </AppBase>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default Layout;

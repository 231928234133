import isEmail from "validator/lib/isEmail";
import moment from "moment";
// import isMobilePhone from 'validator/lib/isMobilePhone';

export const alpha = (value) => {
  if (!/^[a-z]*$/i.test(value)) return "Must contain only letters";
};

export const checkFileExtension = (allowExtensions) => (value) => {
  if (!allowExtensions.includes(/(?:\.([^.]+))?$/.exec(value)[1])) {
    return `Allowed extensions: ${allowExtensions.join(", ")}`;
  }
};

export const composeValidations = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const date = (format) => {
  return (value) => {
    if (value && !moment(value, format, true).isValid()) return "Invalid date";
  };
};

export const email = (value) => {
  if (value && !isEmail(value)) return "Invalid email";
};

export const float = (precision) => (value) => {
  const maxPrecision = precision || 2;
  if (
    value &&
    !new RegExp(`^[-+]?[0-9]+.[0-9]{1,${maxPrecision}}$`).test(value)
  ) {
    return "Invalid float";
  }
};

export const integer = (value) => {
  if (value && !/^[-+]?[0-9]+$/.test(value)) return "Invalid integer";
};

export const json = (value) => {
  if (!value) return;

  try {
    JSON.parse(value);
  } catch (e) {
    return "Invalid JSON";
  }
};

export const match = (name, match) => (value) => {
  if (value !== match) return `${name} must match`;
};

export const minLength = (length) => (value) => {
  if (value && value.length < length) {
    return `Must be at least ${length} characters`;
  }
};

export const phone = (value) => {
  if (value && !value.match(/^[0-9]{8,10}$/)) {
    return "Phone Number is Invalid";
  }
  // if (value && !isMobilePhone(value, 'en-AU')) return 'Invalid phone number';
};

export const required = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    (typeof value === "string" && value.trim() === "") ||
    (Array.isArray(value) && !value.length)
  ) {
    return "Required";
  }
};

export const isUrl = (value) => {
  if (
    value &&
    !value.match(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
    )
  ) {
    return "URL is Invalid";
  }
};

// NOTE: this file is generated automatically, any changes will be overwritten.

import Box from "../Box";
import PropTypes from "prop-types";
import React from "react";
import aboutbanner from "../../../static/assets/about-banner.svg";
import agile from "../../../static/assets/agile.svg";
import automationservices from "../../../static/assets/automation-services.svg";
import Brisktechlogocolor from "../../../static/assets/Brisktech-logo-color.svg";
import Brisktechlogowhite from "../../../static/assets/Brisktech-logo-white.svg";
import careerbanner from "../../../static/assets/career-banner.svg";
import close from "../../../static/assets/close.svg";
import cloudservices from "../../../static/assets/cloud-services.svg";
import computerservices from "../../../static/assets/computer-services.svg";
import contactbanner from "../../../static/assets/contact-banner.svg";
import cooperation from "../../../static/assets/cooperation.svg";
import down from "../../../static/assets/down.svg";
import facebook from "../../../static/assets/facebook.svg";
import gift from "../../../static/assets/gift.svg";
import group from "../../../static/assets/group.svg";
import instagram from "../../../static/assets/instagram.svg";
import linkedin from "../../../static/assets/linkedin.svg";
import mail from "../../../static/assets/mail.svg";
import mobiledevelopmentservices from "../../../static/assets/mobile-development-services.svg";
import pagenotfound from "../../../static/assets/page-not-found.svg";
import robot from "../../../static/assets/robot.svg";
import stopwatchicon from "../../../static/assets/stopwatchicon.svg";
import teammeetingabout from "../../../static/assets/team-meeting-about.svg";
import teamwork from "../../../static/assets/team-work.svg";
import team from "../../../static/assets/team.svg";
import testblog from "../../../static/assets/test-blog.svg";
import twitter from "../../../static/assets/twitter.svg";
import uiuxservices from "../../../static/assets/ui-ux-services.svg";
import user from "../../../static/assets/user.svg";
import vector from "../../../static/assets/vector.svg";

const svgMap = {
  "about-banner": aboutbanner,
  agile: agile,
  "automation-services": automationservices,
  "Brisktech-logo-color": Brisktechlogocolor,
  "Brisktech-logo-white": Brisktechlogowhite,
  "career-banner": careerbanner,
  close: close,
  "cloud-services": cloudservices,
  "computer-services": computerservices,
  "contact-banner": contactbanner,
  cooperation: cooperation,
  down: down,
  facebook: facebook,
  gift: gift,
  group: group,
  instagram: instagram,
  linkedin: linkedin,
  mail: mail,
  "mobile-development-services": mobiledevelopmentservices,
  "page-not-found": pagenotfound,
  robot: robot,
  stopwatchicon: stopwatchicon,
  "team-meeting-about": teammeetingabout,
  "team-work": teamwork,
  team: team,
  "test-blog": testblog,
  twitter: twitter,
  "ui-ux-services": uiuxservices,
  user: user,
  vector: vector,
};

const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg];

  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  );
};

Icon.propTypes = {
  svg: PropTypes.oneOf([
    "about-banner",
    "agile",
    "automation-services",
    "Brisktech-logo-color",
    "Brisktech-logo-white",
    "career-banner",
    "close",
    "cloud-services",
    "computer-services",
    "contact-banner",
    "cooperation",
    "down",
    "facebook",
    "gift",
    "group",
    "instagram",
    "linkedin",
    "mail",
    "mobile-development-services",
    "page-not-found",
    "robot",
    "stopwatchicon",
    "team-meeting-about",
    "team-work",
    "team",
    "test-blog",
    "twitter",
    "ui-ux-services",
    "user",
    "vector",
  ]).isRequired,
};

export default Icon;

import React, { useState } from "react";
import { Link } from "gatsby";
import Icon from "../Icon";
import { pages } from "../../data/pages";
import Navigation from "../Navigation";
import Hamburger from "../Hamburger";
import Section from "../Section";

const Header = () => {
  const [toggled, setToggled] = useState(false);
  const handleToggle = () => {
    setToggled(!toggled);
  };

  return (
    <Section
      as="header"
      containerSx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        pb: [6, null, null, 4],
        position: "static",
        pt: [6, null, null, 4],
      }}
      sx={{
        "~ main": {
          mt: 9,
        },
        bg: "body",
        boxShadow: 2,
        color: "white",
        left: 0,
        mb: 0,
        mt: 0,
        pb: [1, null, null, 6, 6],
        position: "fixed",
        pt: [1, null, null, 6, 6],
        right: 0,
        zIndex: 5,
      }}
    >
      <Link to="/">
        <Icon
          alt="Brisktech"
          svg="Brisktech-logo-color"
          sx={{ color: "text.primary", width: "127px" }}
        />
      </Link>
      {pages && pages.length > 0 && (
        <Navigation navLinks={pages} onClick={handleToggle} toggled={toggled} />
      )}
      <Hamburger
        innerSx={{ m: 2 }}
        onClick={handleToggle}
        sx={{
          bg: "primary",
          borderRadius: 1,
          color: "white",
          display: ["inline-block", null, null, "none"],
          flex: "none",
          position: "sticky",
          width: "30px",
        }}
        toggled={toggled}
      />
    </Section>
  );
};

export default Header;

import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactFileReader from "react-file-reader";
import Button from "../Button";
import InputWrapper from "../InputWrapper";
import Box from "../Box";

const FileUpload = ({ input, setFileAttachment, ...rest }) => {
  const [attachment, setAttachment] = useState({});
  const [fileError, setFileError] = useState("");
  const handleFiles = (files) => {
    if (files.fileList[0].size < 2097152) {
      const fileName = files.fileList[0].name;
      const fileExt = fileName.split(".").pop();
      const fileData = files.base64;
      setAttachment({
        contentType: getContentType(fileExt),
        file: fileData,
        fileName,
      });
    } else {
      setFileError("File is too big! max 2mb");
    }
  };
  setFileAttachment(attachment);

  const getContentType = (ext) => {
    switch (ext) {
      case "doc":
        return "Application/msword";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "csv":
        return "text/csv";
      case "pdf":
        return "application/pdf";
      default: {
        break;
      }
    }
  };

  const fileBgColor = (fileError) => {
    if (fileError) {
      return "error";
    }
    if (attachment.fileName) {
      return "success";
    }
    return "primary";
  };
  return (
    <InputWrapper input={input} {...rest}>
      {({ error, ...inputRest }) => (
        <Box
          as={ReactFileReader}
          base64
          elementId="attachment"
          fileTypes={[".doc", ".docx", ".pdf"]}
          handleFiles={handleFiles}
          multipleFiles={false}
          {...inputRest}
          {...input}
        >
          <Button
            sx={{
              "&:hover": {
                bg: fileBgColor(fileError),
                borderColor: fileBgColor(fileError),
              },
              bg: fileBgColor(fileError),
              borderColor: fileBgColor(fileError),
              py: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            {fileError || attachment.fileName || "Upload Resume"}
          </Button>
        </Box>
      )}
    </InputWrapper>
  );
};

FileUpload.propTypes = {
  input: PropTypes.shape({ error: PropTypes.bool, name: PropTypes.string })
    .isRequired,
  setFileAttachment: PropTypes.func,
};

FileUpload.defaultProps = {
  setFileAttachment: null,
};

export default FileUpload;

import "prism-themes/themes/prism-vs.css";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import get from "lodash/get";
import { ThemeContext } from "styled-components";
import camelCase from "camelcase";
import Section from "../Section";
import Box from "../Box";
import mdToHtml from "../../utilities/mdToHtml";
import ConnectBanner from "../ConnectBanner";
import ShareVia from "../ShareVia";
import SEO from "../SEO";
import mdToPlainText from "../../utilities/mdToPlainText";
import Image from "../Image";

const BlogLayout = ({ pageContext }) => {
  const theme = useContext(ThemeContext);
  const blogData = get(pageContext, "blogData", {});
  const blogTags = get(blogData, "tags", []);
  const textSx = {
    "*": {
      fontFamily: theme.fonts.secondary,
      fontSize: "default",
      lineHeight: theme.lineHeights[4],
    },
  };

  return (
    <>
      <SEO
        article={blogData}
        datePublished={get(blogData, "publishDate", "")}
        description={mdToPlainText(get(blogData, "blogDescription", ""))}
        keywords={get(blogData, "keywords", "")}
        person={{
          givenName: get(blogData, "author", ""),
          id: camelCase(get(blogData, "author", "")),
        }}
        title={get(blogData, "title", "")}
      />
      <Section
        containerSx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "85%",
        }}
        sx={{ mb: 0, mt: 0 }}
      >
        <Box as="h1" sx={{ mb: 7 }}>
          {blogData.title}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "secondary",
          }}
        >
          <Box sx={{ color: "primary", fontSize: 2 }}>{blogData.author}</Box>
          <Box sx={{ color: "text.secondary", fontSize: 1 }}>
            {blogData.publishDate}
          </Box>
        </Box>
      </Section>
      <Section
        containerSx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: ["85%", null, "50%"],
        }}
        sx={{ mb: 0, mt: 0, pt: 0 }}
      >
        <Image img={blogData.blogImage} sx={{ mb: 7, width: "100%" }} />
        {mdToHtml(blogData.blogDescription, textSx)}
      </Section>
      <Section
        containerSx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          width: ["70%", null, "50%"],
        }}
        sx={{ mb: 0, mt: 0, pt: 0 }}
      >
        {blogTags && blogTags.length > 0 && (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: ["column", null, null, "row"],
              fontFamily: "secondary",
              justifyContent: ["flex-start"],
              mb: 7,
            }}
          >
            <Box
              as="h3"
              sx={{
                fontWeight: "bold",
                mb: [2, null, null, 0],
                mr: [0, null, null, 5],
              }}
            >
              Tags
            </Box>
            <Box
              sx={{
                color: "text.primary",
                display: "flex",
                flexWrap: "wrap",
                fontSize: 1,
                justifyContent: "center",
              }}
            >
              {blogTags.map((tag, index) => (
                <Box
                  key={`${tag}-${index}`}
                  as="a"
                  href={tag.tagLink}
                  sx={{
                    "&:hover": { bg: "primaryDark", color: "white" },
                    bg: "primaryLight",
                    borderRadius: 3,
                    mb: [3, 0],
                    mr: 3,
                    px: 5,
                    py: 1,
                  }}
                  target="_blank"
                  to={null}
                >
                  {tag.tagTitle}
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", null, null, "row"],
            fontFamily: "secondary",
            justifyContent: ["flex-end"],
          }}
        >
          <Box
            as="h4"
            sx={{
              fontSize: 3,
              fontWeight: "bold",
              mb: [2, null, null, 0],
              mr: [0, null, null, 5],
            }}
          >
            Share Link
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <ShareVia blogData={blogData} />
          </Box>
        </Box>
      </Section>
      <ConnectBanner />
    </>
  );
};

BlogLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
};

export default BlogLayout;

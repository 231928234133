import React from "react";
import Box from "../../components/Box";
import Section from "../../components/Section";
import Heading from "../../components/Heading";
import Image from "../../components/Image";
import AspectRatio from "../../components/AspectRatio";

const FourOhFour = () => {
  const pageDetails = {
    svg: "page-not-found",
    title: "Page Not Found!",
  };

  return (
    <>
      <Section
        containerSx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        sx={{ mb: 0, mt: 0 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            textAlign: "center",
            width: ["100%", null, "50%"],
          }}
        >
          <Heading as="h1" sx={{ pb: 5, textTransform: "upperCase" }}>
            {pageDetails.title}
          </Heading>
        </Box>
        <AspectRatio ratio={[190, 150]} sx={{ width: ["100%", null, "50%"] }}>
          <Image svgIcon={pageDetails.svg} />
        </AspectRatio>
      </Section>
    </>
  );
};

export default FourOhFour;

import React from "react";
import PropTypes from "prop-types";
import {
  TwitterShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import get from "lodash/get";
import Box from "../Box";
import config from "../../config.json";
import Image from "../Image";
import mdToPlainText from "../../utilities/mdToPlainText";
import longTextToShort from "../../utilities/longTextToShort";

const ShareVia = ({ sx, blogData }) => {
  const iconSx = {
    ":hover": {
      bg: "primary",
    },
    alignItems: "center",
    bg: "primaryDark",
    borderRadius: 2,
    color: "primary",
    cursor: "pointer",
    display: "flex",
    height: "40px",
    justifyContent: "center",
    mx: 3,
    pt: 1,
    width: "40px",
  };
  return (
    <Box sx={{ display: "flex", ...sx }}>
      <TwitterShareButton
        title={`${get(blogData, "title", "")}\n${longTextToShort(
          mdToPlainText(get(blogData, "blogDescription", "")),
          150
        )}`}
        url={`${config.hostName}blog/${get(blogData, "blogId", "")}`}
      >
        <Box sx={iconSx} title="Twitter">
          <Image svgIcon="twitter" />
        </Box>
      </TwitterShareButton>
      <FacebookShareButton
        quote={`${get(blogData, "title", "")}\n${longTextToShort(
          mdToPlainText(get(blogData, "blogDescription", "")),
          450
        )}`}
        url={`${config.hostName}blog/${get(blogData, "blogId", "")}`}
      >
        <Box sx={iconSx} title="Facebook">
          <Image svgIcon="facebook" />
        </Box>
      </FacebookShareButton>
      <LinkedinShareButton
        source={config.hostName}
        title={`${get(blogData, "title", "")}`}
        url={`${config.hostName}blog/${get(blogData, "blogId", "")}`}
      >
        <Box sx={iconSx} title="LinkedIn">
          <Image svgIcon="linkedin" />
        </Box>
      </LinkedinShareButton>
      <EmailShareButton
        body={`${mdToPlainText(get(blogData, "blogDescription", ""))}\n\n`}
        subject={`${get(blogData, "title", "")} - Brisktech`}
        url={`${config.hostName}blog/${get(blogData, "blogId", "")}`}
      >
        <Box sx={iconSx} title="Mail">
          <Image svgIcon="mail" />
        </Box>
      </EmailShareButton>
    </Box>
  );
};

ShareVia.propTypes = {
  blogData: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

ShareVia.defaultProps = {
  blogData: {},
  sx: {},
};

export default ShareVia;

import React from "react";
import get from "lodash/get";
import Box from "../../components/Box";
import Section from "../../components/Section";
import ConnectBanner from "../../components/ConnectBanner";
import Heading from "../../components/Heading";
import Image from "../../components/Image";
import { PAGE_ID } from "../../utilities/constants";
import AboutContainer from "../../containers/AboutContainer";
import mdToHtml from "../../utilities/mdToHtml";
import svgNameFormat from "../../utilities/svgNameFormat";
import SEO from "../../components/SEO";
import Card from "../../components/Card";

const About = () => {
  return (
    <AboutContainer>
      {(aboutPageData) => {
        const aboutData = aboutPageData
          .map((ele) => (ele.id === PAGE_ID.ABOUT_PAGE ? ele : null))
          .find((el) => el);
        const seo = get(aboutData, "seo", {});
        const steps = get(aboutData, "steps", []);
        return (
          <>
            <SEO
              description={get(seo, "description", "")}
              keywords={get(seo, "keywords", "")}
              title={get(seo, "title", "")}
            />
            <Section
              containerSx={{
                alignItems: "center",
                display: "flex",
                flexDirection: ["column", "row-reverse"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              sx={{ mb: 0, mt: 0, pb: 0 }}
            >
              <Image
                svgIcon={svgNameFormat(aboutData.bannerImage)}
                sx={{ width: ["100%", null, "50%"] }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: ["center", null, "left"],
                  width: ["100%", null, "50%"],
                }}
              >
                <Heading as="h1" sx={{ pb: 5 }}>
                  {get(aboutData, "bannerHeading", "")}
                </Heading>
                <Box as="p">{mdToHtml(aboutData.bannerHeadingContent)}</Box>
              </Box>
            </Section>
            {steps && steps.length > 0 && (
              <Section
                containerSx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: ["90%", null, "70%"],
                }}
                sx={{ mb: 0, mt: 0 }}
              >
                {steps.map((step, index) => (
                  <Box
                    key={`${step.stepTitle}_${index}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      as="h1"
                      sx={{
                        display: "flex",
                        py: 5,
                        width: "60%",
                      }}
                    >
                      {step.stepTitle}
                    </Box>
                    <Box
                      sx={{
                        borderLeft: " 4px solid #4CB0DE",
                        ml: 1,
                        p: [3, null, null, 7],
                      }}
                    >
                      <Card
                        sx={{
                          fontFamily: "secondary",
                          fontSize: 2,
                          ml: [1, null, null, 3],
                          p: [3, null, null, 7],
                        }}
                      >
                        {mdToHtml(step.stepDescription)}
                      </Card>
                    </Box>
                  </Box>
                ))}
              </Section>
            )}

            <ConnectBanner />
          </>
        );
      }}
    </AboutContainer>
  );
};

export default About;

const longTextToShort = (text, charLimit) => {
  let dotsPosition = charLimit;
  text.split("").some((char, i) => {
    if (char === ".") {
      if (i < charLimit) {
        dotsPosition = i;
      } else if (i > charLimit) {
        return true;
      }
    }
    return false;
  });
  return `${text.substring(0, dotsPosition)}...`;
};

export default longTextToShort;

export const PAGE_ID = {
  ABOUT_PAGE: "aboutPage",
  BLOGS_PAGE: "blogsPage",
  CAREER_PAGE: "careerPage",
  CONTACT_PAGE: "contactPage",
  INDEX_PAGE: "indexPage",
  SERVICES_PAGE: "servicesPage",
};

export const SUBJECTS = [
  {
    label: "Jobs",
    value: "Brisktech - Contact for Jobs",
  },
  {
    label: "Partnership",
    value: "Brisktech - Contact for Partnership",
  },
  {
    label: "Other",
    value: "Brisktech - Contact for Other",
  },
];

export const COMPANY_EMAIL = {
  INFO: "info@briskteq.com",
};

export const CONTACT_EMAIL_DEFAULT_VALUES = {
  EMAIL_SUBJECT: "Brisktech - Applied for a job",
};

export const COMPANY_MOBILE_NUMBER = { CONTACT_NUMBER: "+91 8097389616" };
export const COMPANY_LOCATION = { SURAT: "Surat, Gujarat, India" };

import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";

const Card = ({ children, hoverSx, latter, latterSx, sx, ...rest }) => {
  const latterStyled = latter
    ? {
        "&:before": {
          alignItems: "center",
          color: "primary",
          content: `"${latter}"`,
          display: "flex",
          fontSize: "17rem",
          fontWeight: "black",
          height: "100%",
          justifyContent: "center",
          lineHeight: 1,
          opacity: 0.1,
          overflow: "hidden",
          position: "absolute",
          right: 0,
          textTransform: "uppercase",
          top: 0,
          transition: "all .35s ease-in-out",
          width: "100%",
          // zIndex: -1,
          ...latterSx,
        },
      }
    : {};
  const hoverStyled = {
    "&:hover": {
      "&:before": latter
        ? {
            opacity: 0.25,
            transform: "scale(1.1)",
          }
        : {},
      boxShadow: 3,
      transition: "all .35s ease-in-out",
      ...hoverSx,
    },
  };
  const cardStyled = {
    bg: "white",
    borderRadius: 2,
    boxShadow: 1,
    display: "inline-flex",
    flex: 1,
    flexDirection: "column",
    // overflow: 'hidden',
    p: 7,
    position: "relative",
    transition: "all .12s ease-in-out",
    // zIndex: 0,
    ...sx,
    ...latterStyled,
    ...hoverStyled,
  };
  return (
    <Box sx={cardStyled} {...rest}>
      {children}
    </Box>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  hoverSx: PropTypes.shape({}),
  latter: PropTypes.string,
  latterSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Card.defaultProps = {
  hoverSx: {},
  latter: null,
  latterSx: {},
  sx: {},
};

export default Card;

import React from "react";
import * as PropTypes from "prop-types";
import get from "lodash/get";
import Box from "../Box";
import Hexagon from "../Hexagon";
import Image from "../Image";
import svgNameFormat from "../../utilities/svgNameFormat";

const HexagonBanner = ({ sx, textSx, imageSx, data }) => {
  const hexagonText = (text) => (
    <Box
      sx={{
        color: "primary",
        fontSize: [2, null, null, 4],
        fontWeight: "bold",
        textTransform: "uppercase",
        ...textSx,
      }}
    >
      {text}
    </Box>
  );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        ml: [-4, null, null, 0],
        ...sx,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          mr: [-4, null, null, -6],
        }}
      >
        <Hexagon
          horizontal
          sx={{ bg: "white" }}
          wrapperSx={{ filter: 2, width: ["130px", null, "200px"] }}
        >
          <Image
            svgIcon={svgNameFormat(get(data[0], "hexagonImage", ""))}
            sx={{
              height: ["50px", null, "80px"],
              ml: [7, 6, 8],
              mt: [5, 5, 7],
              width: ["50px", null, "80px"],
              ...imageSx,
            }}
          />
          <Box sx={{ ml: [-8, -7, -9], mt: [8, 7, 9] }}>
            {hexagonText(get(data[0], "hexagonTitle", ""))}
          </Box>
        </Hexagon>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Hexagon
          horizontal
          sx={{ bg: "white" }}
          wrapperSx={{ filter: 2, width: ["130px", null, "200px"] }}
        >
          <Image
            svgIcon={svgNameFormat(get(data[1], "hexagonImage", ""))}
            sx={{
              height: ["50px", null, "80px"],
              ml: [13, null, 8],
              mt: [5, null, 6, 7],
              width: ["50px", null, "80px"],
              ...imageSx,
            }}
          />
          <Box sx={{ mr: [-8, null, null, -10], mt: [8, 7, 9] }}>
            {hexagonText(get(data[1], "hexagonTitle", ""))}
          </Box>
        </Hexagon>
        <Hexagon
          horizontal
          sx={{ bg: "white" }}
          wrapperSx={{ filter: 2, width: ["130px", null, "200px"] }}
        >
          <Image
            svgIcon={svgNameFormat(get(data[2], "hexagonImage", ""))}
            sx={{
              height: ["50px", null, "80px"],
              ml: [13, null, 8],
              mt: [5, null, 6, 7],
              width: ["50px", null, "80px"],
              ...imageSx,
            }}
          />
          <Box sx={{ mr: [-8, null, null, -10], mt: [8, 7, 9] }}>
            {hexagonText(get(data[2], "hexagonTitle", ""))}
          </Box>
        </Hexagon>
      </Box>
    </Box>
  );
};

HexagonBanner.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  imageSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  textSx: PropTypes.shape({}),
};

HexagonBanner.defaultProps = {
  data: [],
  imageSx: {},
  sx: {},
  textSx: {},
};

export default HexagonBanner;

import React from "react";
import get from "lodash/get";
import Section from "../../components/Section";
import Box from "../../components/Box";
import BlogList from "../../components/BlogsList";
import ConnectBanner from "../../components/ConnectBanner";
import BlogsContainer from "../../containers/BlogsContainer";
import { PAGE_ID } from "../../utilities/constants";
import SEO from "../../components/SEO";

const Blogs = () => {
  return (
    <BlogsContainer>
      {(blogsPageData) => {
        const blogsData = blogsPageData
          .map((ele) => (ele.id === PAGE_ID.BLOGS_PAGE ? ele : null))
          .find((el) => el);
        const seo = get(blogsData, "seo", {});
        return (
          <>
            <SEO
              description={get(seo, "description", "")}
              keywords={get(seo, "keywords", "")}
              title={get(seo, "title", "")}
            />
            <Section sx={{ mb: 0, mt: 0, pb: 0 }}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Box as="h1" sx={{ mb: 5 }}>
                  {get(blogsData, "heading", "")}
                </Box>
                <Box
                  sx={{
                    color: "primaryDark",
                    display: "flex",
                    fontFamily: "secondary",
                    fontSize: 2,
                    width: ["100%", null, "75%", "50%"],
                  }}
                >
                  {get(blogsData, "highlights", "")}
                </Box>
              </Box>
            </Section>
            <Section sx={{ mt: 0 }}>
              <BlogList data={get(blogsData, "blogs", [])} />
            </Section>
            <ConnectBanner />
          </>
        );
      }}
    </BlogsContainer>
  );
};

export default Blogs;

import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const ContactContainer = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContactPageJson {
            edges {
              node {
                bannerHeading
                bannerHeadingContent
                bannerImage
                id
                seo {
                  description
                  keywords
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allContactPageJson: { edges: contactPageData } }) =>
        children(
          contactPageData.map((e) => ({
            ...e.node,
          }))
        )
      }
    />
  );
};

ContactContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ContactContainer;

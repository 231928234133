import React from "react";
import * as PropTypes from "prop-types";
import Box from "../Box";
import Hexagon from "../Hexagon";
import Image from "../Image";
import svgNameFormat from "../../utilities/svgNameFormat";
import Button from "../Button";

const ServicesBanner = ({ sx, textSx, imageSx, data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: ["column", null, null, "row"],
        justifyContent: "center",
        ...sx,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {data.length > 0 &&
          data.map((service, i) => (
            <Box
              key={`service${i}`}
              sx={{ mr: [4, null, null, 7], mt: [2, -4, 0, 0] }}
            >
              <Hexagon
                sx={{ bg: "white" }}
                wrapperSx={{
                  ":hover": { filter: 3 },
                  filter: 1,
                  width: ["130px", null, "200px"],
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Image
                    svgIcon={svgNameFormat(service.serviceImage)}
                    sx={{
                      height: ["50px", null, "80px"],
                      mt: -4,
                      width: ["50px", null, "80px"],
                      ...imageSx,
                    }}
                  />
                  <Box
                    sx={{
                      color: "primaryMedium",
                      fontSize: [2, null, 3],
                      fontWeight: "bold",
                      mt: [1, null, 2],
                      ...textSx,
                    }}
                  >
                    {service.serviceTitle}
                  </Box>
                </Box>
              </Hexagon>
            </Box>
          ))}
      </Box>
      <Button
        link="/about"
        simple
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          ml: [-4, null, null, 4],
          mt: [4, null, null, 0],
        }}
      >
        <Hexagon
          sx={{ bg: "primaryLight" }}
          wrapperSx={{
            filter: 1,
            height: "100px",
            width: "100px",
          }}
        >
          <Box sx={{ display: "flex", ml: [0, null, 3] }}>
            <Box
              sx={{
                color: "primary",
                fontSize: 1,
                fontWeight: "bold",
                width: "50%",
              }}
            >
              Know more
            </Box>
            <Image
              svgIcon="group"
              sx={{
                height: "30px",
                ml: 3,
                mr: -6,
                width: "30px",
              }}
            />
          </Box>
        </Hexagon>
      </Button>
    </Box>
  );
};

ServicesBanner.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  imageSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  textSx: PropTypes.shape({}),
};

ServicesBanner.defaultProps = {
  data: [],
  imageSx: {},
  sx: {},
  textSx: {},
};

export default ServicesBanner;

import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";

const Heading = ({ as, children, ...rest }) => {
  return (
    <Box as={as} {...rest}>
      {children}
    </Box>
  );
};

Heading.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
};

Heading.defaultProps = {
  as: "h2",
  children: null,
};

export default Heading;

import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Field, Form as FinalForm } from "react-final-form";
import Box from "../../components/Box";
import Section from "../../components/Section";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Input from "../../components/Input";
import { composeValidations, email, phone, required } from "../../validations";

const StyleGuide = () => {
  const theme = useContext(ThemeContext);
  const text = "What is Brisktech?";
  const colorsRow1 = [
    {
      backgroundColor: theme.colors.primary,
      border: false,
      name: "Brisk Blue",
      primary: true,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.success,
      border: false,
      name: "Brisk Green",
      primary: false,
      success: true,
      transparent: false,
    },
  ];

  const colorsRow2 = [
    {
      backgroundColor: theme.colors.text.primary,
      border: false,
      name: "Text Primary",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.text.secondary,
      border: false,
      name: "Text Secondary",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.primary,
      border: false,
      name: "Primary",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.primaryMedium,
      border: false,
      name: "Primary Medium",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.primaryLight,
      border: false,
      name: "Primary Light",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.primaryTransparent,
      border: false,
      name: "Primary",
      primary: false,
      success: false,
      transparent: true,
    },
  ];

  const colorsRow3 = [
    {
      backgroundColor: theme.colors.success,
      border: false,
      name: "Success",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.error,
      border: false,
      name: "Error",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.warning,
      border: false,
      name: "Warning",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.black,
      border: false,
      name: "Black",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.white,
      border: true,
      name: "White",
      primary: false,
      success: false,
      transparent: false,
    },
    {
      backgroundColor: theme.colors.body,
      border: true,
      name: "Body Color",
      primary: false,
      success: false,
      transparent: false,
    },
  ];

  const shadows = [
    {
      name: "Shadow",
      shadowType: 1,
    },
    {
      name: "Shadow md",
      shadowType: 2,
    },
    {
      name: "Shadow lg",
      shadowType: 3,
    },
  ];
  const gap = 4;
  const colStyled = {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    fontSize: 1,
    mb: gap,
    minWidth: ["100%", null, null, "calc(100% / 2)"],
    mr: 8,
    pr: gap,
  };
  return (
    <>
      <Section>
        <Box
          sx={{
            display: "flex",
            flexDirection: "coulmn",
            flexWrap: "wrap",
            mt: 5,
          }}
        >
          <Box sx={{ fontSize: 5, fontWeight: theme.fontWeights.bold }}>
            Brisktech
          </Box>
          &nbsp;&nbsp;
          <Box sx={{ fontSize: 5, fontWeight: theme.fontWeights.regular }}>
            Style Guide
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mt: 7,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 10,
              }}
            >
              {colorsRow1.map((colorObject) => (
                <Box sx={{ display: "flex", flexDirection: "column", mr: 5 }}>
                  <Box
                    sx={{
                      backgroundColor: colorObject.backgroundColor,
                      border: colorObject.border ? "1px solid" : "none",
                      borderColor: colorObject.border ? "borderGray" : "none",
                      borderRadius: "50%",
                      display: "flex",
                      height: "90px",
                      width: "90px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 0,
                      fontWeight: theme.fontWeights.regular,
                      textAlign: "center",
                    }}
                  >
                    {colorObject.name}
                    {colorObject.primary && <Box>(Primary)</Box>}
                    {colorObject.transparent && <Box>(Transparent)</Box>}
                    {colorObject.success && <Box>(Success)</Box>}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              {colorsRow2.map((colorObject) => (
                <Box sx={{ display: "flex", flexDirection: "column", mr: 5 }}>
                  <Box
                    sx={{
                      backgroundColor: colorObject.backgroundColor,
                      border: colorObject.border ? "1px solid" : "none",
                      borderColor: colorObject.border ? "borderGray" : "none",
                      borderRadius: "50%",
                      display: "flex",
                      height: "90px",
                      width: "90px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",

                      fontSize: 0,
                      fontWeight: theme.fontWeights.regular,
                      textAlign: "center",
                    }}
                  >
                    {colorObject.name}
                    {colorObject.primary && <Box>(Primary)</Box>}
                    {colorObject.transparent && <Box>(Transparent)</Box>}
                    {colorObject.success && <Box>(Success)</Box>}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              {colorsRow3.map((colorObject) => (
                <Box sx={{ display: "flex", flexDirection: "column", mr: 5 }}>
                  <Box
                    sx={{
                      backgroundColor: colorObject.backgroundColor,
                      border: colorObject.border ? "1px solid" : "none",
                      borderColor: colorObject.border ? "borderGray" : "none",
                      borderRadius: "50%",
                      display: "flex",
                      height: "90px",
                      width: "90px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 0,
                      fontWeight: theme.fontWeights.regular,
                      textAlign: "center",
                    }}
                  >
                    {colorObject.name}
                    {colorObject.primary && <Box>(Primary)</Box>}
                    {colorObject.transparent && <Box>(Transparent)</Box>}
                    {colorObject.success && <Box>(Success)</Box>}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: [5, null, null, 10],
              mt: [8, null, null, 10],
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                fontSize: 0,
              }}
            >
              <Box>Card: radius {theme.radii[2]},</Box>
              <Box>Button and Field : {theme.radii[1]}</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 5,
              }}
            >
              {shadows.map((shadow) => (
                <Box sx={{ display: "flex", flexDirection: "column", mr: 6 }}>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      boxShadow: shadow.shadowType,
                      display: "flex",
                      flexDirection: "column",
                      height: "82px",
                      width: "82px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 0,
                      fontWeight: theme.fontWeights.regular,
                      mt: 5,
                      textAlign: "center",
                    }}
                  >
                    {shadow.name}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", mt: 10 }}>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box sx={{ width: "400px" }}>
              <h1>{text}</h1>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                ml: 22,
              }}
            >
              Heading : Roboto Condensed | 48px | Bold
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box
              sx={{
                fontSize: 4,
                fontWeight: "bold",
                width: "400px",
              }}
            >
              {text}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Sub Heading : Roboto Condensed | 36px | Bold
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box
              sx={{
                fontSize: 3,
                fontWeight: "bold",
                width: "400px",
              }}
            >
              {text}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              content title : Roboto Condensed | 28px | Bold
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box sx={{ width: "400px" }}>
              <h2>{text}</h2>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Heading two: Cabin | 45px | Regular
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box sx={{ width: "400px" }}>
              <h3>{text}</h3>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Heading three: Cabin | 36px | Regular
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box
              sx={{
                fontFamily: "secondary",
                fontSize: 2,
                fontWeight: "regular",
                width: "400px",
              }}
            >
              {text}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Body: Cabin | 24px/36px | Regular
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box
              sx={{
                fontFamily: "secondary",
                fontSize: 2,
                fontWeight: "bold",
                width: "400px",
              }}
            >
              {text}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Body Bold: Cabin | 24px/36px | Bold
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box
              sx={{
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: "regular",
                width: "400px",
              }}
            >
              {text}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Label: Cabin | 18px | Regular
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box
              sx={{
                fontSize: 0,
                fontWeight: "bold",
                textTransform: "upperCase",
                width: "400px",
              }}
            >
              {text}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Button text: Roboto Condensed | 14px | Bold
            </Box>
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Box
              sx={{
                fontSize: 0,
                fontWeight: "regular",
                width: "400px",
              }}
            >
              {text}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontFamily: "secondary",
                fontSize: 1,
                fontWeight: theme.fontWeights.regular,
                justifyContent: "center",
                ml: 22,
              }}
            >
              Error message: Cabin | 14px | Regular
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mt: 12 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Button variant="buttons.primary">Primary</Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 0,
                  fontWeight: theme.fontWeights.regular,
                  mt: 5,
                  textAlign: "center",
                }}
              >
                Primary Button
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 14 }}>
              <Button variant="buttons.primary">Primary</Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 0,
                  fontWeight: theme.fontWeights.regular,
                  mt: 5,
                  textAlign: "center",
                }}
              >
                Button hover
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 14 }}>
              <Button disabled variant="buttons.primary">
                Primary
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 0,
                  fontWeight: theme.fontWeights.regular,
                  mt: 5,
                  textAlign: "center",
                }}
              >
                Button Disabled
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 14 }}>
              <Button
                sx={{ color: "white", width: "48px" }}
                variant="buttons.primary.icon"
              >
                <Icon svg="user" sx={{ mt: 4, width: "25px" }} />
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: "flex", mt: 5 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Button variant="buttons.secondary">Secondary</Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 0,
                  fontWeight: theme.fontWeights.regular,
                  mt: 5,
                  textAlign: "center",
                }}
              >
                Secondary Button
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 14 }}>
              <Button variant="buttons.secondary">Secondary</Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 0,
                  fontWeight: theme.fontWeights.regular,
                  mt: 5,
                  textAlign: "center",
                }}
              >
                Button hover
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 14 }}>
              <Button disabled variant="buttons.secondary">
                Secondary
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 0,
                  fontWeight: theme.fontWeights.regular,
                  mt: 5,
                  textAlign: "center",
                }}
              >
                Button Disabled
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 14 }}>
              <Button
                sx={{ color: "primary", width: "48px" }}
                variant="buttons.secondary.icon"
              >
                <Icon svg="user" sx={{ mt: 4, width: "25px" }} />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mt: 10 }}>
          <h1>{text}</h1>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "secondary",
              fontSize: 2,
              fontWeight: "regular",
              maxWidth: "50%",
              mt: 6,
            }}
          >
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              pellentesque, <b>mi pulvinar porttitor</b> blandit, ipsum nisl
              ullamcorper turpis, non auctor nibh justo et nibh. Quisque porta
              nulla velit, nec accumsan lectus mollis nec. Sed congue neque est.
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 5,
            }}
          >
            <Button variant="buttons.primary">Let&#8217;s Talk</Button>
            <Button sx={{ ml: 14 }} variant="buttons.secondary">
              Learn More
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mt: 10 }}>
          <h3>Contact Us</h3>
          <Box sx={{ mt: 6 }}>
            <FinalForm
              onSubmit={() => {
                // window.alert(`Hii, ${values.firstName}`);
              }}
              render={(formContext) => (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        as={Field}
                        component={Input}
                        label="First Name"
                        name="firstName"
                        validate={required}
                        wrapperSx={colStyled}
                      />
                      <Box
                        as={Field}
                        component={Input}
                        label="Last Name"
                        name="lastName"
                        validate={required}
                        wrapperSx={colStyled}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        mt: 6,
                      }}
                    >
                      <Box
                        as={Field}
                        component={Input}
                        label="Email"
                        name="email"
                        validate={composeValidations(required, email)}
                        wrapperSx={colStyled}
                      />
                      <Box
                        as={Field}
                        component={Input}
                        label="Phone Number"
                        name="phoneNumber"
                        validate={composeValidations(required, phone)}
                        wrapperSx={colStyled}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 6,
                      }}
                    >
                      <Button
                        submitting={formContext.submitting}
                        type="submit"
                        variant="buttons.primary"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            />
          </Box>
        </Box>
        {/* <Box
          sx={{
            bg: "text.primary",
            height: "133px",
            position: "absolute",
            right: 0,
            top: 0,
            width: "187px",
          }}
        >
          <Icon
            svg="Brisktech-logo-color"
            sx={{ ml: 15, mt: 15, width: "25px" }}
          />
          <Icon
            svg="Brisktech-logo-white"
            sx={{ ml: 15, mt: 9, width: "25px" }}
          />
        </Box> */}
      </Section>
    </>
  );
};

export default StyleGuide;

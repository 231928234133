import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const CareerContainer = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allCareerPageJson {
            edges {
              node {
                bannerHeading
                bannerHeadingContent
                bannerImage
                currentOpenings {
                  jobDescription
                  title
                  type
                  location
                  vacancy
                }
                currentOpeningsLabel
                id
                seo {
                  description
                  keywords
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allCareerPageJson: { edges: careerPageData } }) =>
        children(
          careerPageData.map((e) => ({
            ...e.node,
          }))
        )
      }
    />
  );
};

CareerContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CareerContainer;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "../Box";
import Heading from "../Heading";
import Image from "../Image";

const JobCard = ({ children, bodySx, opening, sx, ...rest }) => {
  const { location, type, title, vacancy } = opening;
  const [isOpen, setIsOpen] = useState(false);
  const bodyOpenSx = isOpen
    ? {
        maxHeight: "500rem",
        opacity: "1",
        transform: "translateY(0)",
        transition:
          "max-height 0.7s ease-in-out, opacity 0.35s 0.15s ease-in-out, transform 0.35s 0.15s",
      }
    : {};

  return (
    <Box
      sx={{
        bg: "white",
        borderRadius: 2,
        boxShadow: 2,
        mb: 6,
        overflow: "hidden",
        p: 6,
        position: "relative",
        pr: 4,
        ...sx,
      }}
      {...rest}
    >
      <Box
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          cursor: "pointer",
          // bg: "white",
          // borderRadius: 2,
          // boxShadow: 2,
          ...sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              ...sx,
            }}
          >
            <Heading
              as="h4"
              sx={{
                color: "primary",
                fontFamily: "secondary",
                fontSizes: 2,
                fontWeight: "bold",
                mb: 2,
              }}
            >
              {title}
            </Heading>
            <Image
              svgIcon="down"
              sx={{
                color: "text.secondary",
                cursorPinter: "none",
                flex: "none",
                height: "2.5rem",
                transform: isOpen ? "rotate(180deg)" : "",
                transition: "transform 0.35s",
                width: "2.5rem",
              }}
            />
          </Box>

          <Box
            sx={{
              color: "text.primary",
              display: "flex",
              flexWrap: "wrap",
              fontSize: 0,
            }}
          >
            {vacancy && (
              <Box
                sx={{
                  bg: "primaryLight",
                  borderRadius: 3,
                  mb: [2, null, 0],
                  mr: 3,
                  px: 5,
                  py: 1,
                }}
              >
                {`Opening ${vacancy}`}
              </Box>
            )}
            {location && (
              <Box
                sx={{
                  bg: "primaryLight",
                  borderRadius: 3,
                  mb: [2, null, 0],
                  mr: 3,
                  px: 5,
                  py: 1,
                }}
              >
                {location}
              </Box>
            )}
            {type && (
              <Box
                sx={{
                  bg: "primaryLight",
                  borderRadius: 3,
                  mb: [2, null, 0],
                  mr: 3,
                  px: 5,
                  py: 1,
                }}
              >
                {type}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: "1px solid",
          borderTopColor: "body",
          maxHeight: 0,
          mt: isOpen ? 6 : 0,
          opacity: 0,
          overflow: "hidden",
          pt: isOpen ? 4 : 0,
          transform: "translateY(2rem)",
          transition:
            "max-height 0.7s ease-in-out, opacity 0.35s ease-in-out, transform 0s 0.35s",
          ...bodySx,
          ...bodyOpenSx,
        }}
      >
        <Box>{children || null}</Box>
      </Box>
    </Box>
  );
};
JobCard.propTypes = {
  bodySx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  opening: PropTypes.shape({
    location: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    vacancy: PropTypes.string,
  }),
  sx: PropTypes.shape({}),
};

JobCard.defaultProps = {
  bodySx: {},
  opening: {},
  sx: {},
};

export default JobCard;

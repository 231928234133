import React from "react";
import get from "lodash/get";
import Box from "../../components/Box";
import Section from "../../components/Section";
import Button from "../../components/Button";
import HexagonBanner from "../../components/HexagonBanner";
import IndexContainer from "../../containers/IndexContainer";
import { PAGE_ID } from "../../utilities/constants";
import mdToHtml from "../../utilities/mdToHtml";
import ServicesBanner from "../../components/ServicesBanner";
import KeyCompetencies from "../../components/KeyCompetencies";
import ConnectBanner from "../../components/ConnectBanner";
import SEO from "../../components/SEO";

const Index = () => {
  return (
    <IndexContainer>
      {(indexPageData) => {
        const indexData = indexPageData
          .map((ele) => (ele.id === PAGE_ID.INDEX_PAGE ? ele : null))
          .find((el) => el);
        const heading = get(indexData, "heading", {});
        const about = get(indexData, "about", {});
        const keyCompetencies = get(indexData, "keyCompetencies", {});
        const seo = get(indexData, "seo", {});
        return (
          <>
            <SEO
              description={get(seo, "description", "")}
              keywords={get(seo, "keywords", "")}
              title={get(seo, "title", "")}
            />
            <Section
              containerSx={{
                display: "flex",
                flexDirection: ["column", null, null, "row"],
                flexWrap: "wrap",
                mt: [4, null, null, 1],
              }}
              sx={{ mb: 0, mt: 0 }}
            >
              <Box
                sx={{
                  alignItems: ["center", null, null, "flex-start"],
                  display: "flex",
                  flexDirection: "column",
                  textAlign: ["center", null, null, "left"],
                  width: ["100%", null, null, "50%"],
                }}
              >
                <h1>{get(heading, "heading")}</h1>
                <Box
                  sx={{
                    fontFamily: "secondary",
                    fontSize: 2,
                    fontWeight: "regular",
                    mt: 5,
                    width: ["90%", null, null, "85%"],
                  }}
                >
                  {mdToHtml(get(heading, "headingContent", ""))}
                </Box>
                <Box
                  sx={{
                    // display: "flex",
                    // justifyContent: ["center", null, "left"],
                    mt: 6,
                  }}
                >
                  <Button link="/contact" variant="buttons.primary">
                    {get(heading, "buttonContent", "")}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: [8, null, null, 0],
                  width: ["100%", null, null, "50%"],
                }}
              >
                <HexagonBanner data={get(heading, "headingBanner", [])} />
              </Box>
            </Section>
            <Section sx={{ mt: 0 }}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Box>
                  <h1>{get(about, "aboutHeading", "")}</h1>
                </Box>
                <Box
                  sx={{
                    fontSize: 2,
                    mt: 5,
                    width: ["90%", null, null, "60%"],
                  }}
                >
                  {mdToHtml(get(about, "aboutContent", ""))}
                </Box>
                <ServicesBanner
                  data={get(about, "servicesHexagon", [])}
                  sx={{ mt: [5, null, null, 13] }}
                />
              </Box>
            </Section>
            <Section
              containerSx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
              sx={{ mt: 0 }}
            >
              <h1>{get(keyCompetencies, "keyCompetencyHeading", "")}</h1>
              <KeyCompetencies
                data={get(keyCompetencies, "keyCompetencyList", [])}
                mdSx={{ p: { mb: 3 } }}
              />
            </Section>
            <ConnectBanner />
          </>
        );
      }}
    </IndexContainer>
  );
};

export default Index;

import React, { useState } from "react";
import get from "lodash/get";
import ConnectBanner from "../../components/ConnectBanner";
import Image from "../../components/Image";
import Box from "../../components/Box";
import Heading from "../../components/Heading";
import Section from "../../components/Section";
import JobCard from "../../components/JobCard";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import ContactForm from "../../components/ContactForm/ContactForm";
import { PAGE_ID } from "../../utilities/constants";
import CareerContainer from "../../containers/CareerContainer";
import mdToHtml from "../../utilities/mdToHtml";
import svgNameFormat from "../../utilities/svgNameFormat";
import SEO from "../../components/SEO";

const Career = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const testSx = { "ul,ol": { fontSize: "inherit", pl: 4 } };

  return (
    <CareerContainer>
      {(careerPageData) => {
        const careerData = careerPageData
          .map((ele) => (ele.id === PAGE_ID.CAREER_PAGE ? ele : null))
          .find((el) => el);
        const currentOpeningsData = get(careerData, "currentOpenings", []);
        const seo = get(careerData, "seo", {});
        return (
          <>
            <SEO
              description={get(seo, "description", "")}
              keywords={get(seo, "keywords", "")}
              title={get(seo, "title", "")}
            />
            <Section
              containerSx={{
                alignItems: "center",
                display: "flex",
                flexDirection: ["column", "row-reverse"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              sx={{
                mb: 0,
                mt: 0,
              }}
            >
              <Image
                svgIcon={svgNameFormat(careerData.bannerImage)}
                sx={{ width: ["100%", null, "50%"] }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: ["center", null, "left"],
                  width: ["100%", null, "50%"],
                }}
              >
                <Heading as="h1" sx={{ pb: 5, pt: [5, null, 0] }}>
                  {careerData.bannerHeading}
                </Heading>
                <p>{mdToHtml(careerData.bannerHeadingContent)}</p>
              </Box>
            </Section>
            <Section
              containerSx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              sx={{ mb: 0, mt: 0, pt: 0 }}
            >
              <Box as="h1" sx={{ mb: 8, textAlign: "center" }}>
                {careerData.currentOpeningsLabel}
              </Box>
              {currentOpeningsData && currentOpeningsData.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: [null, "maxWidths.sm", "maxWidths.md"],
                  }}
                >
                  {currentOpeningsData.map((opening, index) => (
                    <JobCard key={`${opening}_${index}`} opening={opening}>
                      {opening.jobDescription && (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box
                            as="p"
                            sx={{ fontSize: 1, lineHeight: 6, pb: 4 }}
                          >
                            {mdToHtml(opening.jobDescription, testSx)}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              onClick={() => toggleModal()}
                              sx={{
                                fontSize: 0,
                                px: 6,
                                py: 5,
                              }}
                            >
                              Apply
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </JobCard>
                  ))}
                </Box>
              )}
            </Section>
            <ConnectBanner />
            <Modal
              isOpen={showModal}
              onClose={toggleModal}
              // style={{
              //   content: {
              //     width: "50%",
              //   },
              // }}
              // sx={{
              //   width: [null, "50%"],
              // }}
              title="Apply now"
            >
              <ContactForm opening />
            </Modal>
          </>
        );
      }}
    </CareerContainer>
  );
};

export default Career;

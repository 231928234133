import React from "react";
import * as PropTypes from "prop-types";
import get from "lodash/get";
import camelCase from "camelcase";
import Box from "../Box";
import Hexagon from "../Hexagon";
import Image from "../Image";
import svgNameFormat from "../../utilities/svgNameFormat";
import mdToHtml from "../../utilities/mdToHtml";

const KeyCompetencies = ({ mdSx, sx, textSx, titleSx, imageSx, data }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        mt: 5,
        ...sx,
      }}
    >
      {data.length > 0 &&
        data.map((keyCompetency, i) => (
          <Box
            key={`keyCompetency${i}`}
            sx={{
              ":hover": { boxShadow: 3 },
              ":hover > div ": { filter: 2 },
              bg: "white",
              borderRadius: 2,
              boxShadow: 1,
              height: "270px",
              ml: 4,
              mr: 4,
              mt: 9,
              textAlign: "left",
              width: "270px",
            }}
          >
            <Hexagon
              as="a"
              href={`/services?service=${camelCase(
                get(keyCompetency, "keyCompetencyTitle", "")
              )}`}
              sx={{ bg: "primaryLight", textAlign: "center" }}
              wrapperSx={{
                filter: 1,
                ml: 4,
                mt: -8,
                width: "120px",
              }}
            >
              <Box sx={{ display: "flex", textAlign: "center" }}>
                <Image
                  svgIcon={svgNameFormat(keyCompetency.keyCompetencyImage)}
                  sx={{
                    width: "72px",
                    ...imageSx,
                  }}
                />
              </Box>
            </Hexagon>
            <Box
              as="a"
              href={`/services?service=${camelCase(
                get(keyCompetency, "keyCompetencyTitle", "")
              )}`}
              sx={{
                color: "primary",
                display: "flex",
                flexWrap: "wrap",
                fontSize: 3,
                fontWeight: "bold",
                ml: 5,
                mr: 5,
                mt: 2,
                ...titleSx,
              }}
            >
              {get(keyCompetency, "keyCompetencyTitle", "")}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                font: "secondary",
                fontSize: 1,
                ml: 5,
                mr: 5,
                mt: 5,
                ...textSx,
              }}
            >
              {mdToHtml(
                get(keyCompetency, "keyCompetencyDescription", ""),
                mdSx
              )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

KeyCompetencies.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  imageSx: PropTypes.shape({}),
  mdSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  textSx: PropTypes.shape({}),
  titleSx: PropTypes.shape({}),
};

KeyCompetencies.defaultProps = {
  data: [],
  imageSx: {},
  mdSx: {},
  sx: {},
  textSx: {},
  titleSx: {},
};

export default KeyCompetencies;
